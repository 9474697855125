// Definición de los datos en formato JSON
const settings = {
    "spanish" : {
        "default" : true,
        "login" : {
          "title" : "Iniciar sesión",
          "fieldUser" : "Usuario",
          "fieldPassword" : "Contraseña",
          "fieldCheck" : "Recordarme",
          "button" : "Iniciar sesión"
        },
        "menu" : [
            {
                "classIcon" : "iconMenu bi bi-house-door home",
                "text" : "Inicio",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-star star",
                "text" : "Favoritos",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-file-text test",
                "text" : "Evaluaciones",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-people groups",
                "text" : "Grupos",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-mortarboard students",
                "text" : "Estudiantes",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-bar-chart-line progress",
                "text" : "Progreso",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-list-ol topics",
                "text" : "Temario",
                "link" : "#"
            }
        ],
        "profile" : "Student",
        "theme" : "light",
        "courses" : [
            {
                "tileClassName" : "",
                "tileIconClass" : "bi bi-file-earmark-excel",
                "typeCourse" : "Certificación",
                "nameCourse" : "Excel 365",
                "level" : "Enterprise",
                "percentageCompleted" : 50,
                "activeElementClass" : "DegradedExcel",
                "topics" : [
                    {
                        "topic" : "Fórmulas y funciones de Excel",
                        "competences" : [
                            {
                                "name" : "Competencia #1"
                            },
                            {
                                "name" : "Competencia #2"
                            },
                            {
                                "name" : "Competencia #3"
                            }
                        ]
                    },
                    {
                        "topic" : "Tablas e informes dinámicos",
                        "competences" : [
                            {
                                "name" : "Competencia #1"
                            },
                            {
                                "name" : "Competencia #2"
                            },
                            {
                                "name" : "Competencia #3"
                            }
                        ]
                    },
                    {
                        "topic" : "Gráficos dinámicos",
                        "competences" : [
                            {
                                "name" : "Competencia #1"
                            },
                            {
                                "name" : "Competencia #2"
                            },
                            {
                                "name" : "Competencia #3"
                            }
                        ]
                    }
                ]
            },
            {
                "tileClassName" : "",
                "tileIconClass" : "bi bi-file-earmark-word",
                "typeCourse" : "Certificación",
                "nameCourse" : "Word 365",
                "level" : "Enterprise",
                "percentageCompleted" : 35,
                "activeElementClass" : "DegradedWord",
                "topics" : [
                    {
                        "topic" : "Fórmulas y funciones de Excel",
                        "competences" : [
                            {
                                "name" : "Competencia #1"
                            },
                            {
                                "name" : "Competencia #2"
                            },
                            {
                                "name" : "Competencia #3"
                            }
                        ]
                    },
                    {
                        "topic" : "Tablas e informes dinámicos",
                        "competences" : [
                            {
                                "name" : "Competencia #1"
                            },
                            {
                                "name" : "Competencia #2"
                            },
                            {
                                "name" : "Competencia #3"
                            }
                        ]
                    },
                    {
                        "topic" : "Gráficos dinámicos",
                        "competences" : [
                            {
                                "name" : "Competencia #1"
                            },
                            {
                                "name" : "Competencia #2"
                            },
                            {
                                "name" : "Competencia #3"
                            }
                        ]
                    }
                ]
            }
        ],
        "messages" : [
            {
                "type" : "success",
                "iconClass" : "bi bi-exclamation-triangle-fill",
                "message" : "¡Atención! Debido a las recientes fallas en el suministro de electricidad se pospone la fecha para presentar su examen hasta próximo aviso."
            },
            {
                "type" : "warning",
                "iconClass" : "bi bi-exclamation-triangle-fill",
                "message" : "¡Atención! Debido a las recientes fallas en el suministro de electricidad se pospone la fecha para presentar su examen hasta próximo aviso."
            },
            {
                "type" : "info",
                "iconClass" : "bi bi-exclamation-triangle-fill",
                "message" : "¡Atención! Debido a las recientes fallas en el suministro de electricidad se pospone la fecha para presentar su examen hasta próximo aviso."
            },
            {
                "type" : "danger",
                "iconClass" : "bi bi-exclamation-triangle-fill",
                "message" : "¡Atención! Debido a las recientes fallas en el suministro de electricidad se pospone la fecha para presentar su examen hasta próximo aviso."
            }
        ],
        "exams" : {
            "psychosocial" : {
                "title" : "Orientación vocacional", //Ir al examen Demo Psicosocial
                "demo" : {
                    "name" : "Demo del test de orientación vocacional",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "24 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "¿Qué rasgos considera Usted que debe tener un aspirante a docente?",
                            "options" : [
                                "Habilidades técnicas avanzadas",
                                "Paciencia y empatía con los estudiantes",
                                "Experiencia en otros campos laborales",
                                "Dominio de varios idiomas"
                            ]
                        },
                        {
                            "question" : "¿Qué tipo de competencias debe tener un docente para desarrollarse de manera efectiva?",
                            "options" : [
                                "Conocimiento profundo de su materia",
                                "Habilidades para la gestión de proyectos",
                                "Capacidades para la venta de productos",
                                "Conocimientos en programación avanzada"
                            ]
                        },
                        {
                            "question" : "¿Qué habilidades emocionales son importantes que tenga desarrolladas como futuro docente?",
                            "options" : [
                                "Capacidad para resolver problemas matemáticos complejos",
                                "Habilidades para la negociación en el mercado laboral",
                                "Empatía y manejo de emociones",
                                "Conocimientos avanzados en tecnología"
                            ]
                        },
                        {
                            "question" : "¿Qué es lo que no se busca en un futuro docente?",
                            "options" : [
                                "Iniciativa para innovar en métodos de enseñanza",
                                "Desinterés por el bienestar de los estudiantes",
                                "Pasión por la educación y el aprendizaje continuo",
                                "Habilidad para trabajar en equipo y colaborar con otros docentes"
                            ]
                        }
                    ]
                },
                "exam" : {
                    "name" : "Test de orientación vocacional",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "22 de Noviembre del 2024",
                    "dueDate" : "25 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "¿Qué rasgos considera Usted que debe tener un aspirante a docente?",
                            "options" : [
                                "Habilidades técnicas avanzadas",
                                "Paciencia y empatía con los estudiantes",
                                "Experiencia en otros campos laborales",
                                "Dominio de varios idiomas"
                            ]
                        },
                        {
                            "question" : "¿Qué tipo de competencias debe tener un docente para desarrollarse de manera efectiva?",
                            "options" : [
                                "Conocimiento profundo de su materia",
                                "Habilidades para la gestión de proyectos",
                                "Capacidades para la venta de productos",
                                "Conocimientos en programación avanzada"
                            ]
                        },
                        {
                            "question" : "¿Qué habilidades emocionales son importantes que tenga desarrolladas como futuro docente?",
                            "options" : [
                                "Capacidad para resolver problemas matemáticos complejos",
                                "Habilidades para la negociación en el mercado laboral",
                                "Empatía y manejo de emociones",
                                "Conocimientos avanzados en tecnología"
                            ]
                        },
                        {
                            "question" : "¿Qué es lo que no se busca en un futuro docente?",
                            "options" : [
                                "Iniciativa para innovar en métodos de enseñanza",
                                "Desinterés por el bienestar de los estudiantes",
                                "Pasión por la educación y el aprendizaje continuo",
                                "Habilidad para trabajar en equipo y colaborar con otros docentes"
                            ]
                        }
                    ]
                }
            },
            "academic" : {
                "title" : "Académico",
                "demo" : {
                    "name" : "Demo de Conocimientos",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "24 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "En el grupo de quinto grado, los alumnos practican tres deportes: 1/3 del grupo juega futbol, 2/5 juegan basquetbol, y el resto natación. ¿Qué parte del grupo practica natación?",
                            "options" : [
                                "4/15",
                                "11/15",
                                "3/5",
                                "7/9"
                            ]
                        },
                        {
                            "question" : "¿Cuál es el resultado de la siguiente división? 15/18 / 3/8",
                            "options" : [
                                "10/8",
                                "120/54",
                                "45/144",
                                "2 1/2"
                            ]
                        },
                        {
                            "question" : "Encontrar el enésimo término de la siguiente sucesión: 8, 13, 18, 23, 28, ...",
                            "options" : [
                                "33",
                                "5n+3",
                                "5n",
                                "2n-1"
                            ]
                        }
                    ]
                },
                "exam" : {
                    "name" : "Conocimientos",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "25 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "Al lanzar un dado de 8 caras, donde tiene los número del 1 al 8, ¿Qué probabilidad existe de obtener un número impar?",
                            "options" : [
                                "1/4",
                                "50%",
                                "5/8",
                                "25%"
                            ]
                        },
                        {
                            "question" : "¿Cuál es el resultado de la siguiente multiplicación? 3/5 x 4/7",
                            "options" : [
                                "12/35",
                                "7/12",
                                "21/20",
                                "2/5"
                            ]
                        },
                        {
                            "question" : "Realiza las siguientes operaciones: 2 + 2 x 3 - 4",
                            "options" : [
                                "6",
                                "4",
                                "0",
                                "5"
                            ]
                        }
                    ]
                }
            },
            "vocational" : {
                "title" : "Vocacional",
                "demo" : {
                    "name" : "Examen Demo",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "24 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "1.-En el grupo de quinto grado, los alumnos practican tres deportes: 1/3 del grupo juega futbol, 2/5 juegan basquetbol, y el resto natación. ¿Qué parte del grupo practica natación?",
                            "options" : [
                                "4/15",
                                "11/15",
                                "3/5",
                                "7/9"
                            ]
                        },
                        {
                            "question" : "¿Cuál es el resultado de la siguiente división? 15/18 / 3/8",
                            "options" : [
                                "10/8",
                                "120/54",
                                "45/144",
                                "2 1/2"
                            ]
                        },
                        {
                            "question" : "Encontrar el enésimo término de la siguiente sucesión: 8, 13, 18, 23, 28, ...",
                            "options" : [
                                "33",
                                "5n+3",
                                "5n",
                                "2n-1"
                            ]
                        }
                    ]
                },
                "exam" : {
                    "name" : "Examen de conocimientos",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "25 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "Al lanzar un dado de 8 caras, donde tiene los número del 1 al 8, ¿Qué probabilidad existe de obtener un número impar?",
                            "options" : [
                                "1/4",
                                "50%",
                                "5/8",
                                "25%"
                            ]
                        },
                        {
                            "question" : "¿Cuál es el resultado de la siguiente multiplicación? 3/5 x 4/7",
                            "options" : [
                                "12/35",
                                "7/12",
                                "21/20",
                                "2/5"
                            ]
                        },
                        {
                            "question" : "Realiza las siguientes operaciones: 2 + 2 x 3 - 4",
                            "options" : [
                                "6",
                                "4",
                                "0",
                                "5"
                            ]
                        }
                    ]
                }
            }
        }
    },
    "english" : {
        "default" : false,
        "login" : {
          "title" : "Login",
          "fieldUser" : "User",
          "fieldPassword" : "Password",
          "fieldCheck" : "Remember me",
          "button" : "Login"
        },
        "menu" : [
            {
                "classIcon" : "iconMenu bi bi-house-door home",
                "text" : "Home",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-star star",
                "text" : "Favorites",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-file-text test",
                "text" : "Tests",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-people groups",
                "text" : "Groups",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-mortarboard students",
                "text" : "Students",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-bar-chart-line progress",
                "text" : "Progress",
                "link" : "#"
            },
            {
                "classIcon" : "iconMenu bi bi-list-ol topics",
                "text" : "Topics",
                "link" : "#"
            }
        ],
        "profile" : "Student",
        "theme" : "light",
        "courses" : [
            {
                "tileClassName" : "",
                "tileIconClass" : "bi bi-file-earmark-excel",
                "typeCourse" : "Certification",
                "nameCourse" : "Excel 365",
                "level" : "Enterprise",
                "percentageCompleted" : 50,
                "activeElementClass" : "DegradedExcel",
                "topics" : [
                    {
                        "topic" : "Formulas and functions",
                        "competences" : [
                            {
                                "name" : "Competence #1"
                            },
                            {
                                "name" : "Competence #2"
                            },
                            {
                                "name" : "Competence #3"
                            }
                        ]
                    },
                    {
                        "topic" : "Dynamic tables and reports",
                        "competences" : [
                            {
                                "name" : "Competence #1"
                            },
                            {
                                "name" : "Competence #2"
                            },
                            {
                                "name" : "Competence #3"
                            }
                        ]
                    },
                    {
                        "topic" : "Dynamic charts",
                        "competences" : [
                            {
                                "name" : "Competence #1"
                            },
                            {
                                "name" : "Competence #2"
                            },
                            {
                                "name" : "Competence #3"
                            }
                        ]
                    }
                ]
            },
            {
                "tileClassName" : "",
                "tileIconClass" : "bi bi-file-earmark-word",
                "typeCourse" : "Certification",
                "nameCourse" : "Word 365",
                "level" : "Enterprise",
                "percentageCompleted": 35,
                "activeElementClass" : "DegradedWord",
                "topics" : [
                    {
                        "topic" : "Formulas and functions",
                        "competences" : [
                            {
                                "name" : "Competence #1"
                            },
                            {
                                "name" : "Competence #2"
                            },
                            {
                                "name" : "Competence #3"
                            }
                        ]
                    },
                    {
                        "topic" : "Dynamic tables and reports",
                        "competences" : [
                            {
                                "name" : "Competence #1"
                            },
                            {
                                "name" : "Competence #2"
                            },
                            {
                                "name" : "Competence #3"
                            }
                        ]
                    },
                    {
                        "topic" : "Dynamic charts",
                        "competences" : [
                            {
                                "name" : "Competence #1"
                            },
                            {
                                "name" : "Competence #2"
                            },
                            {
                                "name" : "Competence #3"
                            }
                        ]
                    }
                ]
            }
        ],
        "messages" : [
            {
                "type" : "success",
                "iconClass" : "bi bi-exclamation-triangle-fill",
                "message" : "Attention! Due to recent failures in the electricity supply, the date to take your exam is postponed until further notice."
            },
            {
                "type" : "warning",
                "iconClass" : "bi bi-exclamation-triangle-fill",
                "message" : "Attention! Due to recent failures in the electricity supply, the date to take your exam is postponed until further notice."
            },
            {
                "type" : "info",
                "iconClass" : "bi bi-exclamation-triangle-fill",
                "message" : "Attention! Due to recent failures in the electricity supply, the date to take your exam is postponed until further notice."
            },
            {
                "type" : "danger",
                "iconClass" : "bi bi-exclamation-triangle-fill",
                "message" : "Attention! Due to recent failures in the electricity supply, the date to take your exam is postponed until further notice."
            }
        ],
        "exams" : {
            "psychosocial" : {
                "title" : "Orientación vocacional",
                "demo" : {
                    "name" : "Demo del test de orientación vocacional",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "24 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "¿Qué rasgos considera Usted que debe tener un aspirante a docente?",
                            "options" : [
                                "Habilidades técnicas avanzadas",
                                "Paciencia y empatía con los estudiantes",
                                "Experiencia en otros campos laborales",
                                "Dominio de varios idiomas"
                            ]
                        },
                        {
                            "question" : "¿Qué tipo de competencias debe tener un docente para desarrollarse de manera efectiva?",
                            "options" : [
                                "Conocimiento profundo de su materia",
                                "Habilidades para la gestión de proyectos",
                                "Capacidades para la venta de productos",
                                "Conocimientos en programación avanzada"
                            ]
                        },
                        {
                            "question" : "¿Qué habilidades emocionales son importantes que tenga desarrolladas como futuro docente?",
                            "options" : [
                                "Capacidad para resolver problemas matemáticos complejos",
                                "Habilidades para la negociación en el mercado laboral",
                                "Empatía y manejo de emociones",
                                "Conocimientos avanzados en tecnología"
                            ]
                        },
                        {
                            "question" : "¿Qué es lo que no se busca en un futuro docente?",
                            "options" : [
                                "Iniciativa para innovar en métodos de enseñanza",
                                "Desinterés por el bienestar de los estudiantes",
                                "Pasión por la educación y el aprendizaje continuo",
                                "Habilidad para trabajar en equipo y colaborar con otros docentes"
                            ]
                        }
                    ]
                },
                "exam" : {
                    "name" : "Test de orientación vocacional",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "25 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "¿Qué rasgos considera Usted que debe tener un aspirante a docente?",
                            "options" : [
                                "Habilidades técnicas avanzadas",
                                "Paciencia y empatía con los estudiantes",
                                "Experiencia en otros campos laborales",
                                "Dominio de varios idiomas"
                            ]
                        },
                        {
                            "question" : "¿Qué tipo de competencias debe tener un docente para desarrollarse de manera efectiva?",
                            "options" : [
                                "Conocimiento profundo de su materia",
                                "Habilidades para la gestión de proyectos",
                                "Capacidades para la venta de productos",
                                "Conocimientos en programación avanzada"
                            ]
                        },
                        {
                            "question" : "¿Qué habilidades emocionales son importantes que tenga desarrolladas como futuro docente?",
                            "options" : [
                                "Capacidad para resolver problemas matemáticos complejos",
                                "Habilidades para la negociación en el mercado laboral",
                                "Empatía y manejo de emociones",
                                "Conocimientos avanzados en tecnología"
                            ]
                        },
                        {
                            "question" : "¿Qué es lo que no se busca en un futuro docente?",
                            "options" : [
                                "Iniciativa para innovar en métodos de enseñanza",
                                "Desinterés por el bienestar de los estudiantes",
                                "Pasión por la educación y el aprendizaje continuo",
                                "Habilidad para trabajar en equipo y colaborar con otros docentes"
                            ]
                        }
                    ]
                }
            },
            "academic" : {
                "title" : "Academic",
                "demo" : {
                    "name" : "Demo de Conocimientos",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "24 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "En el grupo de quinto grado, los alumnos practican tres deportes: 1/3 del grupo juega futbol, 2/5 juegan basquetbol, y el resto natación. ¿Qué parte del grupo practica natación?",
                            "options" : [
                                "4/15",
                                "11/15",
                                "3/5",
                                "7/9"
                            ]
                        },
                        {
                            "question" : "¿Cuál es el resultado de la siguiente división? 15/18 / 3/8",
                            "options" : [
                                "10/8",
                                "120/54",
                                "45/144",
                                "2 1/2"
                            ]
                        },
                        {
                            "question" : "Encontrar el enésimo término de la siguiente sucesión: 8, 13, 18, 23, 28, ...",
                            "options" : [
                                "33",
                                "5n+3",
                                "5n",
                                "2n-1"
                            ]
                        }
                    ]
                },
                "exam" : {
                    "name" : "Conocimientos",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "25 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "Al lanzar un dado de 8 caras, donde tiene los número del 1 al 8, ¿Qué probabilidad existe de obtener un número impar?",
                            "options" : [
                                "1/4",
                                "50%",
                                "5/8",
                                "25%"
                            ]
                        },
                        {
                            "question" : "¿Cuál es el resultado de la siguiente multiplicación? 3/5 x 4/7",
                            "options" : [
                                "12/35",
                                "7/12",
                                "21/20",
                                "2/5"
                            ]
                        },
                        {
                            "question" : "Realiza las siguientes operaciones: 2 + 2 x 3 - 4",
                            "options" : [
                                "6",
                                "4",
                                "0",
                                "5"
                            ]
                        }
                    ]
                }
            },
            "vocational" : {
                "title" : "Vocational",
                "demo" : {
                    "name" : "Examen Demo",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "24 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "1.-En el grupo de quinto grado, los alumnos practican tres deportes: 1/3 del grupo juega futbol, 2/5 juegan basquetbol, y el resto natación. ¿Qué parte del grupo practica natación?",
                            "options" : [
                                "4/15",
                                "11/15",
                                "3/5",
                                "7/9"
                            ]
                        },
                        {
                            "question" : "¿Cuál es el resultado de la siguiente división? 15/18 / 3/8",
                            "options" : [
                                "10/8",
                                "120/54",
                                "45/144",
                                "2 1/2"
                            ]
                        },
                        {
                            "question" : "Encontrar el enésimo término de la siguiente sucesión: 8, 13, 18, 23, 28, ...",
                            "options" : [
                                "33",
                                "5n+3",
                                "5n",
                                "2n-1"
                            ]
                        }
                    ]
                },
                "exam" : {
                    "name" : "Examen de conocimientos",
                    "time" : "3600",
                    "finished" : false,
                    "completionDate" : "21 de Noviembre del 2024",
                    "dueDate" : "25 de Noviembre del 2024",
                    "questions" : [
                        {
                            "question" : "Al lanzar un dado de 8 caras, donde tiene los número del 1 al 8, ¿Qué probabilidad existe de obtener un número impar?",
                            "options" : [
                                "1/4",
                                "50%",
                                "5/8",
                                "25%"
                            ]
                        },
                        {
                            "question" : "¿Cuál es el resultado de la siguiente multiplicación? 3/5 x 4/7",
                            "options" : [
                                "12/35",
                                "7/12",
                                "21/20",
                                "2/5"
                            ]
                        },
                        {
                            "question" : "Realiza las siguientes operaciones: 2 + 2 x 3 - 4",
                            "options" : [
                                "6",
                                "4",
                                "0",
                                "5"
                            ]
                        }
                    ]
                }
            }
        }
    }
}


// "demo" : {
//     "name" : "Examen Demo",
//     "time" : "3600",
//     "finished" : false,
//     "completionDate" : "17 de Agosto del 2024",
//     "dueDate" : "19 de Agosto del 2024",
//     "questions" : [
//         {
//             "question" : "1.-En el grupo de quinto grado, los alumnos practican tres deportes: 1/3 del grupo juega futbol, 2/5 juegan basquetbol, y el resto natación. ¿Qué parte del grupo practica natación?",
//             "options" : [
//                 "4/15",
//                 "11/15",
//                 "3/5",
//                 "7/9"
//             ]
//         },
//         {
//             "question" : "¿Cuál es el resultado de la siguiente división? 15/18 / 3/8",
//             "options" : [
//                 "10/8",
//                 "120/54",
//                 "45/144",
//                 "2 1/2"
//             ]
//         },
//         {
//             "question" : "Encontrar el enésimo término de la siguiente sucesión: 8, 13, 18, 23, 28, ...",
//             "options" : [
//                 "33",
//                 "5n+3",
//                 "5n",
//                 "2n-1"
//             ]
//         }
//     ]
// },
// "exam" : {
//     "name" : "Examen de conocimientos",
//     "time" : "3600",
//     "finished" : false,
//     "completionDate" : "20 de Agosto del 2024",
//     "dueDate" : "20 de Agosto del 2024",
//     "questions" : [
//         {
//             "question" : "Al lanzar un dado de 8 caras, donde tiene los número del 1 al 8, ¿Qué probabilidad existe de obtener un número impar?",
//             "options" : [
//                 "1/4",
//                 "50%",
//                 "5/8",
//                 "25%"
//             ]
//         },
//         {
//             "question" : "¿Cuál es el resultado de la siguiente multiplicación? 3/5 x 4/7",
//             "options" : [
//                 "12/35",
//                 "7/12",
//                 "21/20",
//                 "2/5"
//             ]
//         },
//         {
//             "question" : "Realiza las siguientes operaciones: 2 + 2 x 3 - 4",
//             "options" : [
//                 "6",
//                 "4",
//                 "0",
//                 "5"
//             ]
//         }
//     ]
// }
// "demo" : {
//     "name" : "Examen Demo",
//     "time" : "3600",
//     "finished" : false,
//     "completionDate" : "August 17th 2024",
//     "dueDate" : "August 19th 2024",
//     "questions" : [
//         {
//             "question" : "1.-En el grupo de quinto grado, los alumnos practican tres deportes: 1/3 del grupo juega futbol, 2/5 juegan basquetbol, y el resto natación. ¿Qué parte del grupo practica natación?",
//             "options" : [
//                 "4/15",
//                 "11/15",
//                 "3/5",
//                 "7/9"
//             ]
//         },
//         {
//             "question" : "¿Cuál es el resultado de la siguiente división? 15/18 / 3/8",
//             "options" : [
//                 "10/8",
//                 "120/54",
//                 "45/144",
//                 "2 1/2"
//             ]
//         },
//         {
//             "question" : "Encontrar el enésimo término de la siguiente sucesión: 8, 13, 18, 23, 28, ...",
//             "options" : [
//                 "33",
//                 "5n+3",
//                 "5n",
//                 "2n-1"
//             ]
//         }
//     ]
// },
// "exam" : {
//     "name" : "Examen de conocimientos",
//     "time" : "3600",
//     "finished" : false,
//     "completionDate" : "August 20th 2024",
//     "dueDate" : "August 20th 2024",
//     "questions" : [
//         {
//             "question" : "Al lanzar un dado de 8 caras, donde tiene los número del 1 al 8, ¿Qué probabilidad existe de obtener un número impar?",
//             "options" : [
//                 "1/4",
//                 "50%",
//                 "5/8",
//                 "25%"
//             ]
//         },
//         {
//             "question" : "¿Cuál es el resultado de la siguiente multiplicación? 3/5 x 4/7",
//             "options" : [
//                 "12/35",
//                 "7/12",
//                 "21/20",
//                 "2/5"
//             ]
//         },
//         {
//             "question" : "Realiza las siguientes operaciones: 2 + 2 x 3 - 4",
//             "options" : [
//                 "6",
//                 "4",
//                 "0",
//                 "5"
//             ]
//         }
//     ]
// }


/**
 * Función para obtener las configuraciones de la plataforma
 * @param {string} language 
 * @param {boolean} isLoginPage 
 * @returns 
 */
function DLGetSettings(language = null, isLoginPage = false) {
    try {
        let Info = null

        if (language !== null && language !== '') {
            let tmpInfo = settings[language]

            if (tmpInfo !== undefined && tmpInfo !== null) {
                sessionStorage.setItem('settings', JSON.stringify(settings[language]))
                Info = settings[language]
            }
        } else if (isLoginPage) {
            for (const language in settings) {
                if (settings[language].default) { 
                  sessionStorage.setItem('settings', JSON.stringify(settings[language]))
                  Info = settings[language]
                  break
                }
            }
        } else {
            if (sessionStorage.getItem('settings')) {
                Info = JSON.parse(sessionStorage.getItem('settings'))
            } else {
                for (const language in settings) {
                    if (settings[language].default) { 
                      sessionStorage.setItem('settings', JSON.stringify(settings[language]))
                      Info = settings[language]
                      break
                    }
                }
            }
        }

        return Info
    } catch (error) {
        console.log(error)
    }
}

export { DLGetSettings };