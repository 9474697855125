import { React, useState } from 'react'


const Timer = ({ timeRemaining, size }) => {
    const [showTime, setShowTime] = useState(true) // Nuevo estado para controlar la visibilidad del tiempo

    const toggleTimeVisibility = () => {
        setShowTime(!showTime)
    }

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = seconds % 60
    
        return {
            hours: String(hours).padStart(2, '0'),
            minutes: String(minutes).padStart(2, '0'),
            seconds: String(remainingSeconds).padStart(2, '0'),
        }
    }

    const { hours, minutes, seconds } = formatTime(timeRemaining)

    try {
        return (
            <>
                <div className='Evaluator-Timer-Complete' onClick={toggleTimeVisibility}>
                    {showTime ? ( // Mostrar el tiempo si showTime es true
                        <>
                            <p className='Evaluator-Timer-Complete-Title'>Tiempo restante:</p>

                            <div className='Evaluator-Timer-Complete-Time'>
                                {hours > 0 && ( // Mostrar horas si es necesario
                                    <>
                                        <div className='Evaluator-Timer-Complete-Time-Hours'>
                                            <span className='Evaluator-Timer-Complete-Time-Hour'>{hours}</span>
                                            <span className='Evaluator-Timer-Complete-Time-Hour-Text'>hrs.</span>
                                        </div>
                                        
                                        <span className='Evaluator-Timer-Complete-Time-Separator'>:</span>
                                    </>
                                )}
                                
                                <div className='Evaluator-Timer-Complete-Time-Minutes'>
                                    <span className='Evaluator-Timer-Complete-Time-Minute'>{minutes}</span>
                                    <span className='Evaluator-Timer-Complete-Time-Minute-Text'>Min.</span>
                                </div>

                                <span className='Evaluator-Timer-Complete-Time-Separator'>:</span>

                                <div className='Evaluator-Timer-Complete-Time-Seconds'>
                                    <span className='Evaluator-Timer-Complete-Time-Second'>{seconds}</span>
                                    <span className='Evaluator-Timer-Complete-Time-Second-Text'>Seg.</span>
                                </div>
                            </div>
                        </>
                    ) : ( // Mostrar el ícono y el texto si showTime es false
                        <div className={size === 'L' ? 'Evaluator-Timer-Hide-Complete' : 'Evaluator-Timer-Hide'}>
                            <i className='bi bi-eye Evaluator-Timer-Hide-Icon'></i>
                            <p className='Evaluator-Timer-Hide-Text'>Mostrar tiempo</p>
                        </div>
                    )}
                </div>

                {/* <div className='Evaluator-Timer-Hours'>
                    <p className='Evaluator-Timer-Complete-Title'>Tiempo restante:</p>

                    <span className='Evaluator-Timer-Hours-Time'>{hours > 0 ? `${hours}:` : ''}{minutes}:{seconds} {hours > 0 ? 'hrs.' : 'min.'}</span>
                </div> */}
            </>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Timer };