import React, { useState, useEffect } from 'react'

import { Step } from './Step'
import { FinishMessage } from './FinishMessage'
//import { Timer } from './Timer'
//import { Header } from '../../commons/components/Panel/Header'
import { EvaluatorHeader } from './EvaluatorHeader'

import { GetDemoExam, GetExam, SetFinishedDemoExam, SetFinishedExam, GetExamTime, GetDemoTime, SetRemainingDemoTime, SetRemainingExamTime } from '../../utilities/BL/Settings'


const Evaluator = ({ beforeView, isDemo, typeOfExam, examName, theme, changeTheme }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [examData, setExamData] = useState(null)
    const [selectedAnswers, setSelectedAnswers] = useState({}) // Estado para almacenar las respuestas seleccionadas
    const [activeComponent, setActiveComponent] = useState('Questions')

    const [timeRemaining, setTimeRemaining] = useState(0) // Estado para el tiempo restante
    const [isRunning, setIsRunning] = useState(false) // Estado para controlar si el temporizador está corriendo
    const [showTimeUpMessage, setShowTimeUpMessage] = useState(false) // Nuevo estado para controlar el mensaje
    const [hasTimerStarted, setHasTimerStarted] = useState(false) // Nuevo estado para controlar si el temporizador ha comenzado
    const [isConfirming, setIsConfirming] = useState(false) // Nuevo estado
    const [showConfirmModal, setShowConfirmModal] = useState(false) // Nuevo estado para el modal de confirmación

    const [selectedOption, setSelectedOption] = useState(null) // Nuevo estado para la opción seleccionada

    const [isLoading, setIsLoading] = useState(true)

    const [fontSize, setFontSize] = useState('')


    // Función para aleatorizar un array
    const shuffleArray = (array) => array.sort(() => Math.random() - 0.5)

    /**
     * Funciones que se ejecutan al cargar el componente
     */
    useEffect(() => {
        // let examInfo = null
        // if (isDemo) {
        //     examInfo = GetDemoExam(typeOfExam)
        // } else {
        //     examInfo = GetExam(typeOfExam)
        // }

        let examInfo = isDemo ? GetDemoExam(typeOfExam) : GetExam(typeOfExam)

        if (examInfo && examInfo.questions) {
            // Aleatorizar preguntas
            const shuffledQuestions = shuffleArray(examInfo.questions)

            // Aleatorizar opciones dentro de cada pregunta
            shuffledQuestions.forEach((question) => {
                question.options = shuffleArray(question.options)
            })

            // Actualizar el objeto con las preguntas aleatorias
            examInfo.questions = shuffledQuestions
        }
        
        setExamData(examInfo)


        // Obtener el tiempo inicial y establecerlo en el estado
        const initialTime = isDemo ? GetDemoTime(typeOfExam) : GetExamTime(typeOfExam)
        setTimeRemaining(parseInt(initialTime, 10) || 0)
        setIsRunning(true) // Iniciar el temporizador al cargar la pregunta


        document.getElementById("root").classList.add("BodyComplete")

        setIsLoading(false)
    }, []) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar

    /**
     * Funciones que se ejecutan cuando hay tiempo y está corriendo
     */
    useEffect(() => {
        let intervalId
    
        if (isRunning && timeRemaining > 0) {
          intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => prevTime - 1)
          }, 1000)

          // Marcar que el temporizador ha comenzado después del primer intervalo
            if (!hasTimerStarted) {
                setHasTimerStarted(true)
            }
        } else if (timeRemaining === 0 && hasTimerStarted) { // Verificar si el tiempo se agotó DESPUÉS de que el temporizador haya comenzado
          clearInterval(intervalId)
          handleTimeUp() // Manejar el caso en que el tiempo se agote
        }
    
        return () => clearInterval(intervalId)
      }, [isRunning, timeRemaining])




    /**
     * Funciones para avanzar de pregunta y finalizar examen
     */
    const Common = () => {
        try {
            // Detener el temporizador y registrar el tiempo SOLO si el usuario confirma
            handleStopTimer()

            if (currentQuestionIndex < examData.questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1)
                setIsRunning(true) // Retomar el temporizador al pasar a la siguiente pregunta
            } else {
                //Mostrar el componente FinishMessage
                setActiveComponent('FinishMessage')

                if (isDemo) {
                    SetFinishedDemoExam(typeOfExam)
                } else {
                    SetFinishedExam(typeOfExam)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    /**
     * Función que se ejecuta cuando se hace clic en el botón Siguiente
     * @returns boolean
     */
    const handleNextClick = () => {
        //Verificar si se ha seleccionado una respuesta para la pregunta actual
        if (!selectedAnswers[currentQuestionIndex]) {
            // setIsConfirming(true) // Marcar que se está mostrando la confirmación
            // //Preguntar si desea avanzar a pesar de no haber seleccionado una opción
            // if (window.confirm('¿Estás seguro que deseas avanzar sin elegir alguna opción en esta pregunta?') == false) {
            //     setIsConfirming(false) // Restablecer isConfirming si el usuario cancela
            //     return // Detener la ejecución si no hay respuesta seleccionada y el usuario cancela
            // }
            // setIsConfirming(false) // Restablecer isConfirming si el usuario confirma
            setShowConfirmModal(true) // Mostrar el modal de confirmación
            return // Detener la ejecución para esperar la respuesta del usuario en el modal
        }

        Common()
    }

    /**
     * Función que se ejecuta cuando se confirma el modal para avanzar de pregunta
     * @param {boolean} confirmed 
     */
    const handleConfirm = (confirmed) => {
        setShowConfirmModal(false) // Ocultar el modal de confirmación
        if (confirmed) { Common() }
    }

    /**
     * Función que se ejecuta cuando se detiene el temporizador
     */
    const handleStopTimer = () => {
        setIsRunning(false)

        if (isDemo) {
          SetRemainingDemoTime(timeRemaining,typeOfExam)
        } else {
          SetRemainingExamTime(timeRemaining,typeOfExam)
        }
    }

    /**
     * Función que se ejecuta cuando el tiempo se agota
     */
    const handleTimeUp = () => {
        if (!isConfirming) { // Verificar si NO se está mostrando la confirmación
            // ... Lógica para manejar cuando el tiempo se agota (opcional)
            setShowTimeUpMessage(true)
            handleStopTimer()

            if (isDemo) {
                SetRemainingDemoTime(0,typeOfExam) // Establecer el tiempo restante en 0
                SetFinishedDemoExam(typeOfExam) // Marcar el examen como finalizado
            } else {
                SetRemainingExamTime(0,typeOfExam)
                SetFinishedExam(typeOfExam)
            }
        }
    }

    /**
     * Función que se ejecuta cuando se hace clic en el botón Ir al panel
     */
    const handleGoToFinishMessage = () => {
        setActiveComponent('FinishMessage')
    }
    
    /**
     * Función que se ejecuta cuando se cambia de opción en el Radio button
     */
    const handleOptionChange = (event) => {
        setSelectedAnswers({
          ...selectedAnswers,
          [currentQuestionIndex]: event.target.value 
        })
        
        setSelectedOption(event.target.value) // Actualizar la opción seleccionada
    }
    
    try {
        return (
            <>
                {isLoading ? (
                    <p>Cargando...</p>
                ) : (
                    <>

                        {activeComponent === 'Questions' && examData && ( 
                            <>
                                <EvaluatorHeader timeRemaining={timeRemaining} theme={theme} changeTheme={changeTheme} setFontSize={setFontSize} />
                                
                                <div className="container Evaluator-Question Container-FullHeight">
                                    <Step current={currentQuestionIndex + 1} total={examData.questions.length} />

                                    <p className={`Evaluator-Question-Question ${fontSize}`}>{examData.questions[currentQuestionIndex].question}</p>
                        
                                    {/* Renderizar los radio buttons */}
                                    {examData.questions[currentQuestionIndex].options.map((option, index) => (
                                        <label key={index} className={`Evaluator-Question-Label ${fontSize} ${selectedOption === option ? 'Evaluator-Question-Label-Selected' : ''}`}>
                                            <input type="radio" id={`option-${currentQuestionIndex}-${index}`} name={`answer-${currentQuestionIndex}`} value={option} checked={selectedAnswers[currentQuestionIndex] === option} onChange={handleOptionChange} /> {option}
                                        </label>
                                    ))}

                                    {/* Mostrar el modal de confirmación si es necesario */}
                                    {showConfirmModal && (
                                        <div className="Evaluator-Modal"> {/* O utiliza tu propio componente de modal */}
                                            <div className={`Evaluator-Modal-Content ${fontSize}`}>
                                                <p>¿Estás segur@ que deseas avanzar sin elegir alguna opción en esta pregunta?</p>

                                                <div className='Evaluator-Modal-Buttons'>
                                                    <button type='button' className='Evaluator-Modal-Buttons-Success' onClick={() => handleConfirm(true)}>Sí</button>
                                                    <button type='button' className='Evaluator-Modal-Buttons-Cancel' onClick={() => handleConfirm(false)}>No</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            
                                {/* Botón Next o Finish */}
                                <div className={`Evaluator-Container ${fontSize}`}>
                                    {/* Mostrar el mensaje de tiempo agotado y el botón si es necesario */}
                                    {showTimeUpMessage ? (
                                        <div>
                                            <p>Tiempo agotado</p>
                                            <button className={'Evaluator-Container-BtnNext'} onClick={handleGoToFinishMessage}>Ir al panel</button>
                                        </div>
                                    ) : (
                                        <>
                                            {/* Mostrar el temporizador solo si no se ha agotado el tiempo */}
                                            {/* <Timer timeRemaining={timeRemaining} size={"SM"} /> */}

                                            {/* Mostrar el botón "Next" o "Finish" solo si no se ha agotado el tiempo */}
                                            <button className={'Evaluator-Container-BtnNext'} onClick={handleNextClick}>
                                                {currentQuestionIndex === examData.questions.length - 1 ? 'Finalizar examen' : 'Siguiente pregunta'}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </>
                        )}

                        {
                            /* Mostrar FinishMessage cuando sea necesario */
                            activeComponent === 'FinishMessage' && <FinishMessage beforeView={beforeView} examName={examName} />
                        }
                    </>
                )}
            </>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Evaluator };