import { Timer } from './Timer'



const EvaluatorHeader = ({timeRemaining, theme, changeTheme, setFontSize}) => {
    return (
        <div className='container EvaluatorHeader'>
            {/* Mostrar el temporizador solo si no se ha agotado el tiempo */}
            <Timer timeRemaining={timeRemaining} size={"L"} />

            <div className='EvaluatorHeader-Settings Header'>
                <div className="btn-group Header-Menu">
                    <button type="button" className="dropdown-toggle Header-Button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-gear"></i>
                    </button>
                    
                    <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                            <button className="dropdown-item" type="button" onClick={() => changeTheme()}>
                            {theme === 'dark' ? (
                                <>
                                    <i className="bi bi-sun"></i> Tema claro
                                </>
                            ) : (
                                <>
                                    <i className="bi bi-moon"></i> Tema oscuro
                                </>
                            )}
                            </button>
                        </li>
                        <li className="dropdown-item EvaluatorHeader-Size">
                            <span className="EvaluatorHeader-Size-Small" onClick={() => setFontSize("SmallFont")}>A</span>
                            <span className="EvaluatorHeader-Size-Medium" onClick={() => setFontSize("MediumFont")}>A</span>
                            <span className="EvaluatorHeader-Size-Big" onClick={() => setFontSize("BigFont")}>A</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { EvaluatorHeader };