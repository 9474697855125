import { ValidateSqlInjection } from './../../commons/components/BL/Security'


// const ValidateForm = (formData, userRef, passwordRef) => {
//     const [errors, setErrors] = useState({}) // Estado que maneja los errores

//     try {
//         if (formData.user !== undefined) {
//             if (!formData.user) {
//                 errors.user = 'Ingresa tu usuario'
//                 userRef.current.focus()
//             } else if (ValidateSqlInjection(formData.user)) {
//                 if (formData.user.toUpperCase() !== "FherVaz".toUpperCase()) {
//                     errors.user = 'El usuario que escribiste es inválido'
//                     userRef.current.focus()
//                 } else {
//                     errors.user = 'El usuario que escribiste es inválido'
//                     userRef.current.focus()
//                 }
//             }
//         }

//         if (formData.password !== undefined) {
//             if (!formData.password) {
//                 errors.password = 'Ingresa tu contraseña'
//                 passwordRef.current.focus()
//             } else if (ValidateSqlInjection(formData.password)) {
//                 if (formData.user.toUpperCase() !== "pollo22".toUpperCase()) {
//                     errors.password = 'La contraseña que escribiste es inválida'
//                     passwordRef.current.focus()
//                 } else {
//                     errors.password = 'La contraseña que escribiste es inválida'
//                     passwordRef.current.focus()
//                 }
//             }
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }

/**
 * Función que valida la información ingresada en los campos y devuelve un arreglo con los mensajes de error (en caso de haber)
 * @param {Object} formData 
 * @returns {Array}
 */
const ValidateForm = (formData) => {
    try {
        // Validación detallada de los campos del formulario
        const errors = {}

        if (formData.user !== undefined) {
            if (!formData.user) {
                errors.user = 'Ingresa tu usuario'
            } else {
                if (ValidateSqlInjection(formData.user)) {
                    errors.user = 'El usuario que escribiste es inválido'
                } else {
                    if (formData.user.toUpperCase() !== "fdmdemo".toUpperCase()) {
                        errors.user = 'El usuario que escribiste es incorrecto'
                    }
                }
            }
        }

        if (formData.password !== undefined) {
            if (!formData.password) {
                errors.password = 'Ingresa tu contraseña'
            } else {
                if (ValidateSqlInjection(formData.password)) {
                    errors.password = 'La contraseña que escribiste es inválida'
                } else {
                    if (formData.password.toUpperCase() !== "fdm2479demo".toUpperCase()) {
                        errors.password = 'La contraseña que escribiste es incorrecta'
                    }
                }
            }
        }

        return errors
    } catch (error) {
        console.log(error)
    }
}

export { ValidateForm };










// /**
//  * Función que valida la información ingresada en los campos y devuelve un arreglo con los mensajes de error (en caso de haber)
//  * @param {Object} formData 
//  * @returns {Array}} 
//  */
// const ValidateForm = (formData) => {
//     try {
//         // Validación detallada de los campos del formulario
//         const errors = {}

//         if (formData.id !== undefined) {
//             if (!formData.id) {
//                 errors.id = 'Ingresa el ID'
//             } else if (!/^[A-Za-z]{5}-[A-Za-z]{5}$/.test(formData.id)) {
//                 errors.id = 'El ID que escribiste es inválido'
//             } else if (ValidateSqlInjection(formData.id)) {
//                 errors.id = 'El ID que escribiste es inválido'
//             }
//         }

//         if (formData.user !== undefined) {
//             if (!formData.user) {
//                 errors.user = 'Ingresa tu usuario'
//             } else if (ValidateSqlInjection(formData.user)) {
//                 errors.user = 'El usuario que escribiste es inválido'
//             }
//         }

//         if (formData.password !== undefined) {
//             if (!formData.password) {
//                 errors.password = 'Ingresa tu contraseña'
//             } else if (ValidateSqlInjection(formData.password)) {
//                 errors.password = 'La contraseña que escribiste es inválida'
//             }
//         }

//         return errors
//     } catch (error) {
//         console.log(error)
//     }
// }

//export { ValidateForm };