const FinishMessage = ({ beforeView, examName }) => {
    return (
        <>
            <div className='FinishMessage Container-FullHeight'>
                <div>
                    <i className='bi bi-check-circle FinishMessage-Icon'></i>

                    <h1 className="FinishMessage-Title">¡Felicidades!</h1>

                    <p className="FinishMessage-Text">Haz finalizado tu examen {examName !== "" ? "'" + examName + "'" : ""} con éxito.</p>

                    <button type="button" className="FinishMessage-Button" onClick={() => beforeView()}>Ir al panel</button>
                </div>
            </div>
        </>
    )
}

export { FinishMessage };