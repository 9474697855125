import { GetMessages } from './../../../utilities/BL/Settings';

const Message = () => {
    try {    
        const messages = GetMessages()
        
        return (
            messages.length > 0 ? (
                messages.map((message, index) => {
                    <div key={index} className={`Message-${message.type}`}>
                        <p className='Message-Text'><i className={message.iconClass}></i> {message.message}</p>
                    </div>
                })
            ) : (
                <p>Nada</p>
            )
        )
    } catch (error) {
        console.log(error)    
    }   
}

export { Message };