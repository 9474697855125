//Componentes comunes
import { Button } from './../../commons/components/Common/Button'


const Tiles = ({examDemo, exam, showDemoExamView, showExamView, typeOfExam}) => {
    return (
        <>
            <div className='Dashboard-Tiles'>
                {examDemo.title !== undefined ? (
                    <p className='Dashboard-Tiles-Title'>{examDemo.title}</p>
                ) : (
                    exam.title !== undefined && (
                        <p className='Dashboard-Tiles-Title'>{exam.title}</p>
                    )
                )}

                {examDemo.finished !== undefined && (
                    examDemo.finished ? (
                        <>
                            {/* <p className='Dashboard-Tiles-Title'>{examDemo.name}</p> */}
                            <Button type={'button'} className={'Dashboard-Tiles-BtnInactive'} iconButton={'bi bi-check-circle'} text={' ' + examDemo.name + ' finalizado'} />
                            <p className='Dashboard-Tiles-Legend'>Finalizado el {examDemo.completionDate}</p>
                        </>
                    ) : (
                        <>
                            {/* <p className='Dashboard-Tiles-Title'>{examDemo.name}</p> */}
                            <button type='button' className={'Dashboard-Tiles-BtnSecondary'} onClick={() => showDemoExamView(typeOfExam, examDemo.name)}><i className='bi bi-file-earmark-text'></i> {examDemo.name}</button>
                            <p className='Dashboard-Tiles-Legend'>Disponible hasta el {examDemo.dueDate}</p>
                        </>
                    )
                )}

                {exam.finished !== undefined && (
                    exam.finished ? (
                        <>
                            {/* <p className='Dashboard-Tiles-Title'>{exam.name}</p> */}
                            <Button type={'button'} className={'Dashboard-Tiles-BtnInactive'} iconButton={'bi bi-check-circle'} text={' ' + exam.name + ' finalizado'} />
                            <p className='Dashboard-Tiles-Legend'>Finalizado el {exam.completionDate}</p>
                        </>
                    ) : (
                        <>
                            {/* <p className='Dashboard-Tiles-Title'>{exam.name}</p> */}
                            <button type='button' className={'Dashboard-Tiles-BtnSuccess'} onClick={() => showExamView(typeOfExam, exam.name)}><i className='bi bi-file-earmark-text'></i> {exam.name}</button>
                            <p className='Dashboard-Tiles-Legend'>Disponible hasta el {exam.dueDate}</p>
                        </>
                    )
                )}
            </div>
        </>
    )
}

export { Tiles };


// {/* {demoExamFinished.finished ? (
//     <div className='Dashboard-Tiles'>
//         <p className='Dashboard-Tiles-Title'>Examen Demo</p>
//         <Button type={'button'} className={'Dashboard-BtnInactive'} iconButton={'bi bi-check-circle'} text={' Finalizado'} />
//         <p className='Dashboard-Tiles-Legend'>Finalizado el {demoExamFinished.completionDate}</p>
//     </div>
// ) : (
//     <div className='Dashboard-Tiles'>
//         <p className='Dashboard-Tiles-Title'>Examen Demo</p>
//         {/* <Button type={'button'} className={'Dashboard-BtnSecondary'} iconButton={'bi bi-file-earmark-text'} text={' Ir al examen Demo'} /> /}
//         <button type='button' className={'Dashboard-BtnSecondary'} onClick={() => ShowDemoExamView()}><i className='bi bi-file-earmark-text'></i> Ir al examen Demo</button>
//         <p className='Dashboard-Tiles-Legend'>Disponible hasta el {demoExamFinished.dueDate}</p>
//     </div>
// )} */}
// {/* {examFinished.finished ? (
//     <div className='Dashboard-Tiles'>
//         <p className=''>Examen de ingreso para Preescolar</p>
//         {/* <Button type={'button'} className={'Dashboard-BtnInactive'} iconButton={'bi bi-lock'} text={' Ir al examen'} /> /}
//         <Button type={'button'} className={'Dashboard-BtnInactive'} iconButton={'bi bi-check-circle'} text={' Examen finalizado'} />
//         <p className='Dashboard-Tiles-Legend'>Finalizado el {examFinished.completionDate}</p>
//     </div>
// ) : (
//     <div className='Dashboard-Tiles'>
//         <p className=''>Examen de conocimientos</p>
//         {/* <Button type={'button'} className={'Dashboard-BtnSuccess'} iconButton={'bi bi-file-earmark-text'} text={' Ir al examen'} /> /}
//         <button type='button' className={'Dashboard-BtnSuccess'} onClick={() => ShowExamView()}><i className='bi bi-file-earmark-text'></i> Ir al examen</button>
//         <p className='Dashboard-Tiles-Legend'>Disponible hasta el {examFinished.dueDate}</p>
//     </div>
// )} */}