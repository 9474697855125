import { useEffect, useState } from 'react'

//Componentes comunes
import { Header } from './../../commons/components/Panel/Header'
// import { Menu } from './../../commons/components/Menu/Menu';
// import { MenuDown } from './../../commons/components/Menu/MenuDown';
//import { Button } from './../../commons/components/Common/Button'

//Componente personalizado
import { Message } from './../../commons/components/Common/Message'
import { Tiles } from './Tiles'

//Funciones para obtener datos
import { GetOptionFinishedOfDemoExam, GetOptionFinishedOfExam } from '../../utilities/BL/Settings'


const Dashboard = ({ beforeView, nextView, showDemoExam, showExam, theme, changeTheme, setDefaultTheme }) => {
    const [isLoading, setIsLoading] = useState(true)

    const [demoAcademicExamFinished, setDemoAcademicExamFinished] = useState({})
    const [academicExamFinished, setAcademicExamFinished] = useState({})

    const [demoPsychosocialExamFinished, setDemoPsychosocialExamFinished] = useState({})
    const [psychosocialExamFinished, setPsychosocialExamFinished] = useState({})
    


    const ShowDemoExamView = (typeOfExam,examName) => {
        try {
            showDemoExam(typeOfExam,examName)
            nextView()
        } catch (error) {
            console.log(error)
        }
    }

    const ShowExamView = (typeOfExam,examName) => {
        try {
            showExam(typeOfExam,examName)
            nextView()
        } catch (error) {
            console.log(error)
        }
    }

    const LoadPage = () => {
        try {
            //Establecer el tema a la web
            setDefaultTheme()

            //Obtener el estatus de los exámenes
            setDemoAcademicExamFinished(GetOptionFinishedOfDemoExam("A"))
            setAcademicExamFinished(GetOptionFinishedOfExam("A"))

            setDemoPsychosocialExamFinished(GetOptionFinishedOfDemoExam("P"))
            setPsychosocialExamFinished(GetOptionFinishedOfExam("P"))
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }





    useEffect(() => {
        LoadPage()
    }, [])


    return (
        <>
            {isLoading ? (
                <p>Cargando...</p>
            ) : (
                <>
                    {/* <Header schoolId={"ABCD-EFGH"} schoolName={"Nombre del centro: DGESuM"} beforeView={beforeView} theme={theme} changeTheme={changeTheme} /> */}
                    <Header schoolName={"Nombre del centro: DGESuM"} beforeView={beforeView} theme={theme} changeTheme={changeTheme} />

                    <Message />

                    <main className='container Dashboard Container-FullHeight Container-FlexContent'>
                        <h1 className='Dashboard-Title'>Hola Juan Manuel Léon</h1>
                        
                        {demoAcademicExamFinished.title === undefined && academicExamFinished.title === undefined && demoPsychosocialExamFinished.title === undefined && psychosocialExamFinished.title === undefined ? ( //&& demoVocationalExamFinished.title !== undefined 
                            <p className='Dashboard-Legend'>Por el momento no tienes exámenes agendados.</p>
                        ) : (
                            <div className='Dashboard-TilesContainer'>
                                <Tiles examDemo={demoPsychosocialExamFinished} exam={psychosocialExamFinished} showDemoExamView={ShowDemoExamView} showExamView={ShowExamView} typeOfExam={"P"} />
                                <Tiles examDemo={demoAcademicExamFinished} exam={academicExamFinished} showDemoExamView={ShowDemoExamView} showExamView={ShowExamView} typeOfExam={"A"} />
                                {/* <Tiles examDemo={demoVocationalExamFinished} exam={vocationalExamFinished} showDemoExamView={ShowDemoExamView} showExamView={ShowExamView} typeOfExam={"V"} /> */}
                            </div>
                        )}
                    </main>

                    <div className='Dashboard-Partners'>
                        <img src='/dgesum-logotipo.png' className='Dashboard-Partners-Logo' />
                        {theme === 'dark' ? (
                            <>
                                <img src='/edutest-logotipo-fondo-transparente.png' className='Dashboard-Partners-Logo' />
                            </>
                        ) : (
                            <>
                                <img src='/edutest-logotipo.png' className='Dashboard-Partners-Logo' />
                            </>
                        )}
                    </div>
                
                    <footer className='Dashboard-Footer'>
                        <p>Five Digital Minds® Derechos Reservados 2024</p>
                    </footer>

                    {/*
                        <MenuDown />
                        <Menu />
                    */}
                </>
            )}
        </>
    )
}

export { Dashboard };