import { React, useState } from 'react'

// import { HelmetProvider } from 'react-helmet-async'

//--- Estilos e íconos de Bootstrap ---//
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './styles/styles.css'
//--- Estilos e íconos de Bootstrap ---//


//--- Página Login ---//
import { Login } from './components/Login/Login'
//--- Página login ---//


//--- Página Dashboard ---//
import { Dashboard } from './components/Dashboard/Dashboard'
//--- Página Dashboard ---//


//--- Página Evaluator ---//
import { Evaluator } from './components/Evaluator/Evaluator'
//--- Página Evaluator ---//


//--- Página Evaluator ---//
import { Learning } from './components/Learning/Learning'
//--- Página Evaluator ---//


import { GetTheme, SetTheme } from './utilities/BL/Settings'


function App() {
  const [activeComponent, setActiveComponent] = useState('Login') // Estado para controlar el componente activo
  const [isDemo, setIsDemo] = useState(false)
  const [typeOfExam, setTypeOfExam] = useState("")
  const [examName, setExamName] = useState("")
  
  const [theTheme, setTheTheme] = useState('')
  const [isMexicoTheme, setIsMexicoTheme] = useState(false)


  
  //--- Funciones para mostrar u ocultar los componentes principales ---//
  const ShowLoginView = () => {
    setActiveComponent('Login')
  }

  const ShowDashboardView = () => {
    setActiveComponent('Dashboard')
  }

  const ShowEvaluatorView = () => {
    setActiveComponent('Evaluator')
  }
  //--- Funciones para mostrar u ocultar los componentes principales ---//



  //--- Funciones para saber qué tipo de examen se va a mostrar ---//
  const ShowDemoExam = (typeOfExam,examName) => {
    setIsDemo(true)
    setTypeOfExam(typeOfExam)
    setExamName(examName)
  }

  const ShowExam = (typeOfExam,examName) => {
    setIsDemo(false)
    setTypeOfExam(typeOfExam)
    setExamName(examName)
  }
  //--- Funciones para saber qué tipo de examen se va a mostrar ---//



  //--- Funciones para establecer el tema a la web ---//
  const SetDefaultTheme = () => {
    try {
        // const Theme = GetTheme()

        // if (Theme.toLowerCase() === 'light') {
        //     document.body.classList.remove('DarkTheme')
        //     setTheTheme('light')
        // } else if (Theme.toLowerCase() === 'dark') {
        //     document.body.classList.add('DarkTheme')
        //     setTheTheme('dark')
        // }
        
        let Theme

        if (theTheme === "") {
          Theme = GetTheme()  
        } else {
          Theme = theTheme
        }

        if (Theme.toLowerCase().includes('light')) {
            document.body.classList.remove('DarkTheme')
            setTheTheme('light')

            if (isMexicoTheme) {
              document.body.classList.add('MexicoTheme')
            } else {
              document.body.classList.remove('MexicoTheme')
            }
        } else if (Theme.toLowerCase().includes('dark')) {
            document.body.classList.add('DarkTheme')
            setTheTheme('dark')

            if (isMexicoTheme) {
              document.body.classList.add('MexicoTheme')
            } else {
              document.body.classList.remove('MexicoTheme')
            }
        }
    } catch (error) {
      console.log(error)
    }
  }

  const ChangeTheme = () => {
      try {
          // if (theTheme.toLowerCase() === 'light') {
          //     document.body.classList.add('DarkTheme')
          //     setTheTheme('dark')
          //     //SetTheme('dark')
          // } else if (theTheme.toLowerCase() === 'dark') {
          //     document.body.classList.remove('DarkTheme')
          //     setTheTheme('light')
          //     //SetTheme('light')
          // }

          if (theTheme.toLowerCase().includes('light')) {
            document.body.classList.add('DarkTheme')
            setTheTheme('dark')
            //SetTheme('dark')

            if (isMexicoTheme) {
              document.body.classList.add('MexicoTheme')
            } else {
              document.body.classList.remove('MexicoTheme')
            }
          } else if (theTheme.toLowerCase().includes('dark')) {
            document.body.classList.remove('DarkTheme')
            setTheTheme('light')
            //SetTheme('light')

            if (isMexicoTheme) {
              document.body.classList.add('MexicoTheme')
            } else {
              document.body.classList.remove('MexicoTheme')
            }
          }
      } catch (error) {
        console.log(error)
      }
  }
  //--- Funciones para establecer el tema a la web ---//



  //--- Funciones para establecer el tema de Gobierno a la web ---//
  const SetRiactivosTheme = () => {
    try {
        document.body.classList.remove('MexicoTheme')
        setIsMexicoTheme(false)
      } catch (error) {
        console.log(error)
      }
    }
    
    const SetMexicoTheme = () => {
      try {
        document.body.classList.add('MexicoTheme')
        setIsMexicoTheme(true)
      } catch (error) {
        console.log(error)
      }
  }
  //--- Funciones para establecer el tema de Gobierno a la web ---//


  
  return (
    <>
      {/* <HelmetProvider> */}
        {/* //--- Página login ---// */}
        {activeComponent === 'Login' && <Login nextView={ShowDashboardView} setRiactivosTheme={SetRiactivosTheme} setMexicoTheme={SetMexicoTheme} />}
        
        {/* //--- Página Dashboard ---// */}
        {activeComponent === 'Dashboard' && <Dashboard beforeView={ShowLoginView} nextView={ShowEvaluatorView} showDemoExam={ShowDemoExam} showExam={ShowExam} theme={theTheme} changeTheme={ChangeTheme} setDefaultTheme={SetDefaultTheme} />}
        
        {/* //--- Página Evaluator ---//*/}
        {activeComponent === 'Evaluator' && <Evaluator beforeView={ShowDashboardView} isDemo={isDemo} typeOfExam={typeOfExam} examName={examName} theme={theTheme} changeTheme={ChangeTheme} />}
      {/* </HelmetProvider> */}
    </>


    //--- Página Material didáctico ---//
    // <Learning />
  )
}

export default App;